.main-menu {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
  > ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > li {
      list-style: none;
      font-size: 1rem;
      > a {
        padding: 10px 8px 10px 8px;
        display: inline-block;
        font-weight: normal;
        text-decoration: none;
        color: $primary;
        &:hover {
          text-decoration: underline;
        }
      }
      &.active {
        > a {
          font-weight: bold;
          text-decoration: none;
          &:hover {
            text-decoration: none;
            transition: all 225ms ease-in 0s;
          }
        }
      }
    }
  }
}
